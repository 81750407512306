.navbarContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px;
  padding-right: 34px;
  background-color: #ffffff;
}

.teethContainer {
  padding: 0 !important;
}

@media (min-width: 1600px) {
  .teethContainer {
    max-width: -moz-available !important;
    /* WebKit-based browsers will ignore this. */
    max-width: -webkit-fill-available !important;
    /* Mozilla-based browsers will ignore this. */
    max-width: fill-available !important;
  }
}