:root {
  --primary: #314363 !important;
  --marinerBlue: #346BD1 !important;
  --lightSolitudeGray: #E0E8F7 !important;
  --glaucous: #6c86b5 !important;
  --radicalRed: #FF1C5C !important;
  --cardinalRed: #C21044 !important;
  --carnationPink: #FF98CC !important;
  --paleVioletRed: #DE5896 !important;
  --mayaBlue: #68D6FF !important;
  --bleuDeFrance: #307CE7 !important;
  --aeroBlue: #8eb2f4 !important;
  --bluetifulBlue: #3a79ee !important;
  --poloBlue: '#869DC5' !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}