.container {
  position: fixed;
  height: 110px;
  width: 405px;
  left: calc(50% - 202.5px);
  bottom: 56px;
  background-color: var(--marinerBlue);
  border-radius: 16px;
  z-index: 1051;
  padding: 23px 24px;
  display: flex;
  align-items: center;
}

.notificationIcon {
  margin-right: 24px;
  justify-content: center;
  border-radius: 50px;
  height: 50px;
  width: 50px;
  align-items: center;
}

.notificationTypeText {
  font-size: 16px;
  color: var(--aeroBlue);
}

.notificationMessage {
  font-size: 22px;
  color: white;
  font-weight: 600;
  line-height: 30.8px;
}

.bgSecondary {
  background-color: var(--bluetifulBlue);
}

.failedIconText {
  color: red;
  text-align: center;
  font-size: 23px;
  margin-top: 8px;
}

.iconImg {
  margin-left: 14px;
  margin-top: 16px;
}
